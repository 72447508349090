import * as React from "react";
import { Spinner, makeStyles } from "@fluentui/react-components";
import type { SpinnerProps } from "@fluentui/react-components";

const useStyles = makeStyles({
  centerSpinnerOnPage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    textAlign: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});

export function PincitesSpinner({
  id = "spinner",
  size = PincitesSpinnerSize.TINY,
  label = "",
  appearance = PincitesSpinnerAppearance.PRIMARY,
  pageLoadSpinner = false,
}: {
  id: string;
  label?: string;
  size?: PincitesSpinnerSize;
  appearance?: PincitesSpinnerAppearance;
  pageLoadSpinner?: boolean;
}): React.JSX.Element {
  const styles = useStyles();

  return (
    <Spinner
      id={id}
      className={pageLoadSpinner ? styles.centerSpinnerOnPage : ""}
      size={getSpinnerSize(size)}
      label={label}
      appearance={getSpinnerAppearance(appearance)}
      aria-label={label || "Loading"}
    />
  );
}

export enum PincitesSpinnerSize {
  EXTRA_TINY = "extra-tiny",
  TINY = "tiny",
  EXTRA_SMALL = "extra-small",
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  EXTRA_LARGE = "extra-large",
  HUGE = "huge",
}

export enum PincitesSpinnerAppearance {
  PRIMARY = "PRIMARY",
  INVERTED = "INVERTED",
}

function getSpinnerAppearance(appearance: PincitesSpinnerAppearance): SpinnerProps["appearance"] {
  switch (appearance) {
    case PincitesSpinnerAppearance.PRIMARY:
      return "primary";
    case PincitesSpinnerAppearance.INVERTED:
      return "inverted";
  }
}

function getSpinnerSize(appearance: PincitesSpinnerSize): SpinnerProps["size"] {
  switch (appearance) {
    case PincitesSpinnerSize.EXTRA_TINY:
      return "extra-tiny";
    case PincitesSpinnerSize.TINY:
      return "tiny";
    case PincitesSpinnerSize.EXTRA_SMALL:
      return "extra-small";
    case PincitesSpinnerSize.SMALL:
      return "small";
    case PincitesSpinnerSize.MEDIUM:
      return "medium";
    case PincitesSpinnerSize.LARGE:
      return "large";
    case PincitesSpinnerSize.EXTRA_LARGE:
      return "extra-large";
    case PincitesSpinnerSize.HUGE:
      return "huge";
  }
}
